import {
  Container,
  Grid,
  InputLabel,
  TextField,
  Typography,
  Stack,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Slider,
} from "@mui/material";
import React from "react";
// import { View, Text } from "react-native";
import { Box } from "@mui/system";
import * as SVG from "../assets";
import UserData from "../components/userData";
import profile from "../assets/profile.svg";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import { useLocation, useNavigate } from "react-router-dom";

import { Image } from "@mui/icons-material";
import { serviceImg } from "../assets/images";
import { useState } from "react";
import { IMAGE_BASE_URL } from "../utils/constant";
function valuetext(value) {
  return `${value}°C`;
}
function UserProfile() {
  const location = useLocation();
  const [check, setCheck] = React.useState(false);
  const [ide, setIde] = React.useState("");
  const user = location?.state?.item;
  const navigate = useNavigate();
  const handleCheck = (id) => {
    setIde(id);
    setCheck(!check);
  };
  console.log("interest  ====> ", user?.reportedUser?.interests);
  var buttonType = user?.reportedUser?.interests?.split(",");
  var arr = [
    {
      id: 1,
      profile: profile,
      email: "@jacob_w",
      dateJoined: "12/02/22",

      content: "$125.56",
    },

    {
      id: 6,
      profile: profile,
      email: "@jacob_w",
      dateJoined: "12/02/22",

      content: "$125.56",
    },
    {
      id: 2,
      profile: profile,
      email: "@jacob_w",
      dateJoined: "12/02/22",

      content: "'$125.56'",
    },
    {
      id: 3,
      profile: profile,
      email: "@jacob_w",
      dateJoined: "12/02/22",

      content: "'$125.56'",
    },
    {
      id: 4,
      profile: profile,
      email: "@jacob_w",
      dateJoined: "12/02/22",

      content: "'$125.56'",
    },
  ];
  const [value, setValue] = useState([20, 37]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  console.log("Payout ====> ", user?.payouts);
  return (
    <Container alignItems="center">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={4}
        mt={"80px"}
        // ml={"20px"}
      >
        <img src={SVG.left} onClick={() => navigate("/accountReported")} />
        <Typography
          sx={{ fontFamily: "Roboto", fontSize: "42px", fontWeight: "700" }}
        >
          User Profile
        </Typography>
        <img src={SVG.menue} />
      </Stack>

      <Box
        sx={{
          // backgroundColor: "#F4F4F4",
          mt: "30px",
          // alignItems: "center",
          // justifyContent: "center",
        }}
      >
        <Stack
          sx={{
            // backgroundColor: "#F4F4F4",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={`${IMAGE_BASE_URL}${user?.reportedUser?.profile}`}
            style={{ width: 80, height: 80, borderRadius: "100px" }}
          />
          <Typography>{user?.reportedUser?.userName || "@jacob_w"}</Typography>
        </Stack>
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          // backgroundColor={"red"}
        >
          <Stack
            sx={{
              // backgroundColor: "#F4F4F4",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{ color: "#000000", fontFamily: "Roboto", fontSize: "22px" }}
            >
              {user?.reportedUser?.following}
            </Typography>
            <Typography
              sx={{ color: "#000000", fontFamily: "Roboto", fontSize: "13px" }}
            >
              Following
            </Typography>
          </Stack>
          <Stack
            sx={{
              mx: 5,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{ color: "#000000", fontFamily: "Roboto", fontSize: "22px" }}
            >
              {user?.reportedUser?.followers}
            </Typography>
            <Typography
              sx={{ color: "#000000", fontFamily: "Roboto", fontSize: "13px" }}
            >
              Followers
            </Typography>
          </Stack>
          <Stack
            sx={{
              // backgroundColor: "#F4F4F4",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{ color: "#000000", fontFamily: "Roboto", fontSize: "22px" }}
            >
              {user?.reportedUser?.superFans}
            </Typography>
            <Typography
              sx={{ color: "#000000", fontFamily: "Roboto", fontSize: "13px" }}
            >
              Subscribers
            </Typography>
          </Stack>
        </Grid>
        <Typography
          sx={{
            color: "#000000",
            fontFamily: "Roboto",
            fontSize: "15px",
            mt: 5,
          }}
        >
          Bio
        </Typography>

        <Typography
          border={"2px solid grey"}
          padding={"15px"}
          borderRadius={"5px"}
        >
          {user?.reportedUser?.about || "Videhope user"}
        </Typography>

        {user?.reportedUser?.interests && (
          <Typography
            sx={{
              color: "#000000",
              fontFamily: "Roboto",
              fontSize: "15px",
              mt: 2,
            }}
          >
            Interests
          </Typography>
        )}

        {user?.reportedUser?.interests && (
          <Box
            border={"2px solid grey"}
            // paddingLeft="20px"
            padding={"15px"}
            borderRadius={"5px"}
          >
            {buttonType.map((item) => (
              <Button
                variant="outlined"
                key={item}
                sx={{
                  color: "#000000",
                  fontFamily: "Avenir LT Std",
                  fontSize: "12px",
                  borderRadius: "35px",
                  // border: "5px dashed green",
                  borderColor: "#000000",
                  ml: 2,
                  my: { xs: "4px", sm: "2px" },
                }}
              >
                {item}
              </Button>
            ))}
          </Box>
        )}

        <Typography
          sx={{
            color: "#000000",
            fontFamily: "Roboto",
            fontSize: "15px",
            mt: 2,
          }}
        >
          Contents
        </Typography>

        <Grid
          border={"2px solid grey"}
          borderRadius={"5px"}
          pb={1}
          direction="row"
          container
          alignSelf="center"
        >
          {user?.contents?.map(
            (item) =>
              item?.type != "Audio" && (
                <Grid xs={12} md={3} sm={6}>
                  {item?.type == "Image" ? (
                    <img
                      src={IMAGE_BASE_URL + item?.path}
                      width={"100%"}
                      height={182}
                      mt={20}
                    />
                  ) : (
                    <video
                      width={"100%"}
                      height={182}
                      controls
                      style={{ marginTop: 20 }}
                    >
                      <source
                        src={IMAGE_BASE_URL + item?.path}
                        type="video/mp4"
                      />
                    </video>
                  )}
                  <Typography
                    color="rgba(255, 255, 255, 0.95)"
                    // color={"red"}
                    fontFamily={"Avenir LT Std"}
                    fontSize={13}
                    mt={-4}
                    ml={1}
                  >
                    {item?.description}
                  </Typography>
                </Grid>
              )
          )}
        </Grid>

        {user?.payouts?.length > 0 && (
          <Typography
            sx={{
              color: "#000000",
              fontFamily: "Roboto",
              fontSize: "24px",
              fontWeight: "700",
              mt: 4,
            }}
          >
            Payout history
          </Typography>
        )}

        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            backgroundColor: "#F5F5F5",
            my: 1,
          }}
        >
          {/* <TableContainer sx={{ maxHeight: 600 }}> */}
          <Table stickyHeader aria-label="sticky table">
            <TableBody sx={{ height: 20 }}>
              {user?.payouts?.map((row) => (
                <TableRow
                  // onClick={() => (setIde(row.id), setCheck(!check))}
                  key={row.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    // backgroundColor: check ? "green" : "red",
                  }}
                >
                  <TableCell align="center" scope="row">
                    <Grid container alignItems={"center"}>
                      <img src={user?.reportedUser?.profile} />
                      <Typography
                        ml={"10px"}
                      >{`${user?.reportedUser?.userName}`}</Typography>
                    </Grid>
                  </TableCell>
                  <TableCell align="center"></TableCell>

                  <TableCell align="center">{row?.requestedDateTime}</TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center">
                    {row?.requestedAmount + " GNF"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {/* </TableContainer> */}
        </Paper>
      </Box>
    </Container>
  );
}
export default UserProfile;
