import { Button, Box, Stack } from '@mui/material';
import React from 'react';
import { accountingImage } from '../assets/images';
import { Link, useNavigate } from 'react-router-dom';
import PageHeader from '../components/PageHeader';
import { Assignment } from '@mui/icons-material';

const Accounting = () => {
  return (
    <div>
      <PageHeader title="Accounting" />

      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection="column">
        <Stack justifyContent="center" mb={1} alignItems="center">
          <div className="card mb-3">
            <img src={accountingImage} className="img-fluid" />
          </div>
          {/* 
        <Button
          onClick={() => navigate('/subscription')}
          sx={{
            bgcolor: 'white',
            color: 'grey',
            boxShadow: '2px 2px 4px  #000',
            borderRadius: '60px',
            px: 14,
            py: 1.4,
            mt: 3,
            textTransform: 'capitalize',
          }}
        >
          Subscriptions
        </Button> */}
          <Link to="/filterEarning">
            <Button variant="outlined" startIcon={<Assignment />} fullWidth>
              Withdraw Request
            </Button>
          </Link>
          {/* 
        <Button
          onClick={() => navigate('/Creators')}
          sx={{
            bgcolor: 'white',
            color: 'grey',
            boxShadow: '2px 2px 4px  #000',
            borderRadius: '60px',
            px: 12.2,
            py: 1.4,
            mt: 2,
            textTransform: 'capitalize',
          }}
        >
          Creator Earnings
        </Button> */}

          {/* <Button
          onClick={() => navigate('/earnings')}
          sx={{
            bgcolor: 'white',
            color: 'grey',
            boxShadow: '2px 2px 4px  #000',
            borderRadius: '60px',
            px: 11.9,
            py: 1.4,
            mt: 2,
            textTransform: 'capitalize',
          }}
        >
          Subscribed Amount
        </Button> */}
        </Stack>
      </Box>
    </div>
  );
};

export default Accounting;
