import { Button, Box, Stack, Container } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { customerServices } from '../assets/images';
import Loading from '../components/Loading';
import { GetCustomeServices } from '../utils/api-calls';
import { Email, Group, Verified } from '@mui/icons-material';
import PageHeader from '../components/PageHeader';
// import Masonry from "@mui/lab/Masonry";

const items = [
  { label: 'Messages', route: '/chat', icon: <Email /> },
  { label: 'Kankira Users', route: '/all-users', icon: <Group /> },
  { label: 'Verify Creators', route: '/verifyCreator', icon: <Verified /> },
];

const CustomerService = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [allStaff, setAllStaff] = useState({});

  console.log('🚀 ~ file: customer-services.js:18 ~ CustomerService ~ allStaff:', allStaff);

  const { messages, reportedContents, reportedUsers } = allStaff;

  useEffect(() => {
    // const ctrl = new AbortController();
    // (async () => {
    //   setLoading(true);
    //   const res = await GetCustomeServices({ signal: ctrl.signal });
    //   console.log('res in servises callss=>', res?.data);
    //   console.log('res in servises callss=>', res?.data?.messages);
    //   setAllStaff(res?.data || {});
    //   setLoading(false);
    // })();
    // return () => {
    //   ctrl.abort();
    // };
  }, []);

  if (loading) return <Loading />;

  return (
    <div>
      <PageHeader title="Customer Service" />
      <Container maxWidth="sm">
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
          <Box mb={3}>
            <img src={customerServices} className="img-fluid" alt="cover" />
          </Box>
          <Stack width={1} gap={2} my={3} p={4} justifyContent="center" alignItems="center">
            {items.map(item => (
              <Link className="w-100" key={item.route} to={item.route} state={{ item: item }}>
                <Button fullWidth startIcon={item.icon} variant="outlined">
                  {item.label}
                </Button>
              </Link>
            ))}
          </Stack>
        </Box>
      </Container>
    </div>
  );
};

export default CustomerService;
