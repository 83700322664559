import { useCallback, useEffect } from 'react';

function useUser() {
  const user = JSON.parse(localStorage.getItem('usemsg') || '');
  const isAdmin = user?.role === 'Admin';

  const handleStorage = useCallback(e => {
    console.log(e, 'storage');
  }, []);

  useEffect(() => {
    window.addEventListener('storage', handleStorage);

    return () => {
      window.removeEventListener('storage', handleStorage);
    };
  }, [handleStorage]);

  return { user, isAdmin };
}

export default useUser;
