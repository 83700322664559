import { MoreVert } from '@mui/icons-material';
import {
  Avatar,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { useContext, useState } from 'react';
import { WithdrawContext } from '../../pages/filter-accounting';
import { ApproveRequest, RejectRequest } from '../../utils/api-calls';
import { toast } from 'react-toastify';
import moment from 'moment';

const menuItemsMap = {
  pending: [
    { value: 'approved', label: 'Approve' },
    { label: 'Reject', value: 'denied' },
  ],
  approved: [{ value: 'denied', label: 'Reject' }],
  denied: [{ label: 'Approve', value: 'approved' }],
};

const apiMap = {
  approved: {
    handler: ApproveRequest,
    successMsg: 'Request approved successfully!',
  },
  denied: {
    handler: RejectRequest,
    successMsg: 'Request rejected successfully!',
  },
};

function RequestListItem({ data }) {
  const { tabValue, getList } = useContext(WithdrawContext);
  const { request, user } = data;
  const { requestedDateTime, requestedAmount, bankAccount, walletId, id } = request;
  const { fullName, profile, email } = user;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => setAnchorEl(null);

  const handleItemClick = async value => {
    handleClose();
    console.log(value);

    if (apiMap[value]) {
      const { handler, successMsg } = apiMap[value];
      try {
        const resp = await handler(id);
        console.log(resp, '--resp');

        toast.success(successMsg);
      } catch (error) {
        toast.error(error?.message || 'Something went wrong!');
      }
      getList();
    }
  };

  return (
    <>
      <ListItem sx={{ px: 0 }}>
        <ListItemIcon>
          <Avatar alt={fullName} src={profile === '' ? '/' : profile} />
        </ListItemIcon>
        <ListItemText>
          <Stack flexWrap="wrap" direction="row" alignItems="center" columnGap={1}>
            <Typography>{fullName}</Typography>
          </Stack>
          <Stack flexWrap="wrap" direction="row" alignItems="center" columnGap={1}>
            <Typography color="gray" variant="caption">
              {email}
            </Typography>
            <Typography pr={1} color="gray" variant="caption">
              &mdash; {moment(requestedDateTime).format('YYYY-MM-DD')}
            </Typography>
          </Stack>
          <Stack flexWrap="wrap" direction="row" alignItems="center" columnGap={1}>
            <Typography color="gray" variant="caption">
              Account No: {bankAccount}
            </Typography>
            <Typography pr={1} color="gray" variant="caption">
              &nbsp; Amount: {requestedAmount}
            </Typography>
          </Stack>
        </ListItemText>
        <ListItemIcon>
          <IconButton onClick={e => setAnchorEl(e.currentTarget)}>
            <MoreVert />
          </IconButton>
        </ListItemIcon>
      </ListItem>

      {/* menu */}
      <Menu id="menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {(menuItemsMap[tabValue] || []).map(({ label, value }) => (
          <MenuItem key={value} value={value} onClick={() => handleItemClick(value)} dense>
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default RequestListItem;
