import React from 'react';
import UserData from '../components/user-data';
import { Container } from '@mui/material';
import PageHeader from '../components/PageHeader';
const Users = () => {
  return (
    <Container maxWidth="xl" sx={{ px: 0 }}>
      <PageHeader title="All app users" />
      <UserData />
    </Container>
  );
};

export default Users;
