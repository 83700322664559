import { Box, Button, Card, CardContent, Grid, IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { employedPortal, empPic } from '../assets/images';
import '../css/employe-portal.css';
import { getAllStaff } from '../services/api/api-actions';
import { GetAll } from '../utils/api-calls';
import { IMAGE_BASE_URL } from '../utils/constant';
import Avatar from 'react-avatar';
import PageHeader from '../components/PageHeader';
import { Add, Edit, Visibility } from '@mui/icons-material';
const EmployeePortal = () => {
  const navigate = useNavigate();
  const [allStaff, setAllStaff] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      const res = await GetAll();
      console.log('res in screen=>', res);
      setAllStaff(res?.data || []);
    })();
  }, []);
  return (
    <div>
      <PageHeader title="Users" />
      <Grid>
        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box>
            <img alt="Pic here" src={employedPortal} className="image" />
          </Box>
          <Box width={1} mt={3} textAlign="center" maxWidth="300px">
            <Button
              variant="outlined"
              startIcon={<Add />}
              fullWidth
              onClick={() => navigate('/createNewUser')}
            >
              Create New User
            </Button>
          </Box>
        </Grid>
        <Grid container spacing={3} mt={3}>
          {allStaff?.map((item, index) => (
            <Grid key={item?.id} item xs={12} md={4}>
              <Card elevation={0} className="shadow">
                <CardContent>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Stack direction="row" alignItems="center">
                      <Box>
                        {`${item?.profile}` !== '' ? (
                          <Avatar
                            size="3rem"
                            round
                            name={item?.name}
                            src={`${IMAGE_BASE_URL}${item?.profile}`}
                          />
                        ) : (
                          <Avatar size="3rem" round name={item?.name} />
                        )}
                      </Box>
                      <Box>
                        <Typography ml="5px">{item?.name}</Typography>
                        <Typography display="block" variant="caption" ml="5px">
                          {item?.email}
                        </Typography>
                      </Box>
                    </Stack>
                    <Stack alignItems="center" gap={0.5} flexDirection="row">
                      <IconButton onClick={() => navigate('/detail', { state: { item: item } })} size="small">
                        <Visibility />
                      </IconButton>
                      <IconButton
                        onClick={() => navigate('/editProfile', { state: { item: item } })}
                        size="small"
                      >
                        <Edit />
                      </IconButton>
                    </Stack>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </div>
  );
};

export default EmployeePortal;
