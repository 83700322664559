import { Box, Stack, Typography, useTheme } from '@mui/material';
import { useEffect } from 'react';
import useUser from '../../../hooks/useUser';
import moment from 'moment';

const FORMAT = 'hh:mm A';
function Message({ isLast, scrollRef, detail }) {
  const {
    palette: { primary },
  } = useTheme();

  const { user } = useUser();

  const {
    to,
    from,
    description,
    type,
    isSeen,
    messageFile,
    id,
    createdAt,
    createdBy,
    updatedAt,
    updatedBy,
    deletedAt,
    deletedBy,
  } = detail || {};

  const isMine = user?.id === from;

  useEffect(() => {
    const t = setTimeout(() => {
      if (isLast) {
        scrollRef?.current?.scrollIntoView({ behavior: 'instant', block: 'end', inline: 'end' });
      }
    }, 50);

    return () => {
      clearTimeout(t);
    };
  }, [isLast, scrollRef]);

  return (
    <Stack flexDirection="row" width={1} justifyContent={isMine ? 'flex-end' : 'flex-start'}>
      <Box
        className="rounded shadow-sm my-2 py-1 px-2"
        width="fit-content"
        bgcolor={isMine ? primary.main : '#ece7e7'}
        minWidth="150px"
        color={isMine ? 'white' : 'black'}
      >
        <Typography>{description}</Typography>

        <Typography display="block" textAlign="end" variant="caption">
          {moment(createdAt).format(FORMAT)}
        </Typography>
      </Box>
    </Stack>
  );
}

export default Message;
