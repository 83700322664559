import { useCallback, useEffect, useState } from 'react';
import { GetAllUsers } from '../../../utils/api-calls';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Check, Send } from '@material-ui/icons';
import { toast } from 'react-toastify';
import client from '../../../services/api/axios-interceptor';
import UserListItem from './UserListItem';

function UsersList() {
  const [users, setUsers] = useState([]);
  const [message, setMessage] = useState('');
  const [selected, setSelected] = useState({});
  const [allSelected, setAllSelected] = useState(false);
  const [loaders, setLoaders] = useState({ form: false, users: true });

  useEffect(() => {
    (async () => {
      const res = await client.get('api/user/search-users/');
      console.log('res users', res);
      setUsers(res?.data?.data ?? []);
      setLoaders(curr => ({ ...curr, users: false }));
    })();
  }, []);

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();

      if (!message?.trim()) {
        toast.error('Message cannot be empty!');
        return;
      }

      const userIds = [];

      Object.keys(selected).forEach(k => {
        if (selected[k] === true) {
          userIds.push(Number(k));
        }
      });

      if (userIds.length === 0) {
        toast.error('Please select at least one user!');
        return;
      }

      const payload = { userIds, description: message, notification_type: 'admin_notification' };

      setLoaders(curr => ({ ...curr, form: true }));

      const res = await client.post('api/notifications/send-notification-to-users', payload);
      console.log(res, 'sender');

      setLoaders(curr => ({ ...curr, form: false }));

      if (res.status === 200) {
        setSelected({});
        toast.success('Notification sent successfully!');
      }
    },
    [message, selected]
  );

  console.log(selected);

  const handleChecked = useCallback((e, user) => {
    setSelected(prev => ({ ...prev, [user.id]: e.target.checked }));
  }, []);

  if (loaders.users) {
    return (
      <Stack width={1} alignItems="center" mt="25dvh">
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <>
      <Box textAlign="center">
        <Button
          onClick={() => {
            if (allSelected) {
              setAllSelected(false);
              setSelected({});
              return;
            }

            const selection = {};
            users.forEach(u => {
              selection[u.id] = true;
            });
            setSelected(selection);
            setAllSelected(true);
          }}
          variant="contained"
          sx={{ mb: 2 }}
          size="small"
          endIcon={<Check />}
        >
          {allSelected ? 'Unselect All' : 'Select All'}
        </Button>
      </Box>
      <Stack gap={1} height="75dvh">
        <Stack gap={1} flex="1" minHeight="0px" sx={{ overflowY: 'scroll' }}>
          {users.map(u => (
            <UserListItem key={u.id} user={u} isSelected={!!selected[u.id]} onChange={handleChecked} />
          ))}
        </Stack>
        <Box flexShrink={0} flexBasis="4rem">
          <form onSubmit={handleSubmit}>
            <Stack alignItems="center" direction="row">
              <TextField
                required
                placeholder="Write your notification message..."
                fullWidth
                multiline
                maxRows={3}
                value={message}
                disabled={loaders.form}
                onChange={({ target }) => setMessage(target.value)}
              />
              <IconButton disabled={loaders.form} type="submit">
                {loaders.form ? <CircularProgress size="1.5rem" /> : <Send />}
              </IconButton>
            </Stack>
          </form>
        </Box>
      </Stack>
    </>
  );
}

export default UsersList;
