import { Container, Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import { useState, useEffect, useMemo, useCallback, createContext } from 'react';
import PageHeader from '../components/PageHeader';

import { useLocation, useNavigate } from 'react-router-dom';
import { getWithdrawRequests } from '../utils/api-calls';
import RequestList from '../components/requests/RequestList';

export const WithdrawContext = createContext({
  getList: async signal => {},
  tab: 0,
  tabValue: '',
  loading: true,
});

const tabParamKey = {
  0: 'pending',
  1: 'approved',
  2: 'denied',
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const FilterEarning = () => {
  const { search } = useLocation();
  const paramTab = new URLSearchParams(search).get('tab');

  const [tab, setTab] = useState(() => {
    if (paramTab && tabParamKey[Number(paramTab)]) {
      return Number(paramTab);
    }

    return 0;
  });

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const type = tabParamKey[tab];

  const getList = useCallback(
    async signal => {
      setData([]);
      setLoading(true);
      const res = await getWithdrawRequests({ signal, params: { status: type } });
      setData(res?.data);
      setLoading(false);

      return res;
    },
    [type]
  );

  useEffect(() => {
    const ctrl = new AbortController();
    getList(ctrl.signal);

    return () => {
      ctrl.abort();
    };
  }, [type, getList]);

  const ctxValue = useMemo(
    () => ({ getList, tab, tabValue: tabParamKey[tab], loading }),
    [getList, loading, tab]
  );

  return (
    <WithdrawContext.Provider value={ctxValue}>
      <Box>
        <PageHeader title="Withdraw Requests" />

        <Container maxWidth="sm">
          <Tabs
            variant="fullWidth"
            value={tab}
            onChange={(e, v) => {
              setTab(v);
              navigate(`/filterEarning?tab=${v}`, { replace: true });
            }}
            aria-label="basic tabs example"
          >
            <Tab label="Pending" {...a11yProps(0)} />
            <Tab label="Approved" {...a11yProps(1)} />
            <Tab label="Rejected" {...a11yProps(2)} />
          </Tabs>

          <Box mt={2}>
            <RequestList loading={loading} data={data} />
          </Box>
        </Container>
      </Box>
    </WithdrawContext.Provider>
  );
};

export default FilterEarning;
