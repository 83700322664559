import {
  Avatar,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';

function UsersListItem({ data }) {
  const { userName, id, profile } = data;

  return (
    <Link className="reset-link" to={`/chat/${id}`} state={data}>
      <ListItem sx={{ px: 0 }}>
        <ListItemButton disableGutters>
          <ListItemIcon>
            <Avatar alt={userName} src={profile === '' ? '/' : profile} />
          </ListItemIcon>
          <ListItemText>
            <Stack direction="row" justifyContent="space-between" gap={1}>
              <Typography>{userName}</Typography>
            </Stack>
          </ListItemText>
        </ListItemButton>
      </ListItem>
    </Link>
  );
}

export default UsersListItem;
