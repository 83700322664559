import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { createNewUser } from '../assets/images';
import { Register } from '../utils/api-calls';
import PageHeader from '../components/PageHeader';

const CreateNewUser = () => {
  const navigate = useNavigate();
  const [file, setFile] = React.useState();
  const [payload, setPayload] = React.useState({
    Name: '',
    Email: '',
    AccessTo: '',
    Password: '',
    ConfirmPassword: '',
    Profile: '',
    Role: 'Staff',
  });

  const handleImageChange = event => {
    setFile(event.target.files[0]);
  };

  const onSubmit = async e => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('Profile', file);
      formData.append('Role', payload.Role);
      formData.append('Password', payload.Password);
      formData.append('AccessTo', payload.AccessTo);
      formData.append('Name', payload.Name);
      formData.append('Email', payload.Email);

      const res = await Register(formData);

      if (res?.succeeded) {
        navigate('/dashboard');
        return;
      }
      alert(res?.Message || res?.message);
    } catch (error) {
      if (error?.response?.data?.Message == 'Account Already Exist With Given Email') {
        alert('Account Already Exist With Given Email');
      }
    }
  };

  return (
    <div>
      <PageHeader title="Create a new user" />
      <form onSubmit={onSubmit}>
        <Box display={'flex'} flexDirection="column" justifyContent={'center'} alignItems={'center'}>
          <Card>
            <CardContent>
              <img alt="pic here" src={createNewUser} className="img-fluid mb-3" />
              <Stack gap={2}>
                <TextField
                  onChange={event => {
                    setPayload({ ...payload, Name: event?.target?.value });
                  }}
                  id="standard-basic"
                  label="Name"
                  variant="standard"
                  autoComplete="off"
                  required
                />
                <TextField
                  onChange={event => {
                    setPayload({ ...payload, Email: event?.target?.value });
                  }}
                  id="standard-basic"
                  label="Email"
                  variant="standard"
                  autoComplete="off"
                  required
                />
                <TextField
                  onChange={event => {
                    setPayload({ ...payload, Password: event?.target?.value });
                  }}
                  id="standard-basic"
                  label="Password"
                  variant="standard"
                  required
                  autoComplete="off"
                />
                <input type="file" accept="image/*" onChange={handleImageChange} />
                {/* {imageDataURL && <img src={imageDataURL} alt="Preview" />} */}
                <TextField
                  onChange={event => {
                    setPayload({
                      ...payload,
                      ConfirmPassword: event?.target?.value,
                    });
                  }}
                  id="standard-basic"
                  label="ConfirmPassword"
                  required
                  variant="standard"
                  autoComplete="off"
                />
                <Box mb={2}>
                  <FormControl sx={{ width: '100%', borderColor: 'white' }} size={'small'}>
                    <InputLabel id="demo-simple-select-label">Users Access</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={payload?.AccessTo}
                      label="Age"
                      onChange={event => {
                        setPayload({ ...payload, AccessTo: event?.target?.value });
                      }}
                    >
                      <MenuItem value="Accounting">Accounting</MenuItem>
                      <MenuItem value="CreatorsPanel">Creators Panel</MenuItem>
                      <MenuItem value="CustomerService">Customer Services</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Button variant="contained" fullWidth type="submit">
                  Create
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </Box>
      </form>
    </div>
  );
};

export default CreateNewUser;
