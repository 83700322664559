import { Button, Container, Divider, Stack, Switch, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useState, useEffect } from 'react';
import { newestPic } from '../assets/images';
import { updateStaff } from '../services/api/api-actions';
import { useLocation } from 'react-router-dom';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { UpdateStaff } from '../utils/api-calls';
import { IMAGE_BASE_URL } from '../utils/constant';
import Avatar from 'react-avatar';
import PageHeader from '../components/PageHeader';
const EditProfile = () => {
  const [accounting, setAccounting] = useState(false);
  const [creatorPanel, setCreatorPanel] = useState(false);
  const [customerService, setCustomerService] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const location = useLocation();
  const myData = location?.state?.item;
  const { id, name, email, profile, accessTo } = myData;
  const [selectedValues, setSelectedValues] = useState('');
  const [isBlock, setIsBlock] = useState(null);
  const [payload, setPayload] = useState({
    staffId: id,
    accessTo: accessTo,
    isBlocked: true,
  });

  useEffect(() => {
    let acc = accessTo.split(',');
    console.log('🚀 ~ file: edit.js:26 ~ React.useEffect ~ acc:', acc);
    for (let i = 0; i < acc.length; i++) {
      if (acc[i] == 'CreatorsPanel' || 'Creators Panel') {
        return setCreatorPanel(true);
      }

      if (acc[i] == 'Accounting') {
        return setAccounting(true);
      }

      if (acc[i] == 'CustomerServices' || 'Customer Services') {
        return setCustomerService(true);
      }
    }
    console.log('🚀 ~ file: edit.js:26 ~ React.useEffect ~ acc:', acc);
  }, [accessTo]);

  const handleCheckboxChange = e => {
    console.log(e.target.name, e.target.checked);

    const { name, checked } = e.target;
    let newValues = '';
    if (name === 'Accounting') {
      return setAccounting(!accounting);
    }
    if (name === 'CreatorsPanel') {
      return setCreatorPanel(!creatorPanel);
    }
    if (name === 'CustomerServices') {
      return setCustomerService(!customerService);
    }
    if (checked) {
      newValues = selectedValues ? selectedValues + ',' + name : name;
    } else {
      newValues = selectedValues.replace(new RegExp('\\b' + name + '\\b,?', 'g'), '').replace(/^,|,$/g, '');
    }
    setSelectedValues(newValues);
    setPayload(preState => {
      return {
        ...preState,
        accessTo: selectedValues,
      };
    });

    console.log('🚀 ~ file: edit.js:37 ~ handleCheckboxChange ~ payload:', payload);
  };
  const handleBlocked = () => {
    setPayload(prev => ({ ...prev, isBlocked: !prev.isBlocked }));
    console.log('🚀 ~ file: edit.js:183 ~ EditProfile ~ isBlocked:', payload);
  };

  const updateData = async e => {
    e.preventDefault();

    if (e) {
      console.log(payload);
      return;
    }
    setSubmitting(true);

    try {
      const resp = await UpdateStaff(payload);

      console.log('resp', resp);
      console.log('🚀 ~ file: edit.js:50 ~ updateData ~ payload:', payload);
    } catch (error) {
      console.log('🚀 ~ file: edit.js:49 ~ updateData ~ error:', error);
    }
    setSubmitting(false);
  };

  return (
    <Container maxWidth="sm">
      <form onSubmit={updateData}>
        <PageHeader title="Edit User Profile" />

        <Box>
          <Stack alignItems="center" mb={1} gap={1}>
            {profile !== '' ? (
              <Avatar src={`${IMAGE_BASE_URL}${profile}` || newestPic} name={name} round />
            ) : (
              <Avatar name={name} round />
            )}

            <Box mt={1} textAlign="center">
              <Typography>{name}</Typography>
              <Typography variant="body2">{email}</Typography>
            </Box>
          </Stack>

          <Divider sx={{ width: 1, mb: 2 }} color="gray" />

          <FormControlLabel
            className="m-0"
            value="isBlock"
            label="Block"
            labelPlacement="start"
            control={<Switch name="isBlock" onChange={handleBlocked} color="primary" />}
          />

          <Typography mt={1} fontWeight={600}>
            Login Access
          </Typography>
          <Divider sx={{ width: 1 }} color="gray" />

          <Stack gap={2} alignItems="flex-start">
            <FormControlLabel
              className="m-0"
              value="accounting"
              label="Accounting"
              labelPlacement="start"
              control={
                <Switch
                  checked={accounting}
                  name="Accounting"
                  onChange={handleCheckboxChange}
                  color="primary"
                />
              }
            />

            <FormControlLabel
              className="m-0"
              value="creators_panel"
              label="Creators Panel"
              labelPlacement="start"
              control={
                <Switch
                  checked={creatorPanel}
                  name="CreatorsPanel"
                  onChange={handleCheckboxChange}
                  color="primary"
                />
              }
            />

            <FormControlLabel
              className="m-0"
              value="customer_services"
              label="Customer Services"
              labelPlacement="start"
              control={
                <Switch
                  checked={customerService}
                  name="CustomerServices"
                  onChange={handleCheckboxChange}
                  color="primary"
                />
              }
            />
          </Stack>
        </Box>

        <div className="text-center my-3">
          <Button disabled={submitting} variant="contained" type="submit">
            Save
          </Button>
        </div>
      </form>
    </Container>
  );
};

export default EditProfile;
