import React, { useEffect, useState } from 'react';
import { GetAll, dashboadData } from '../utils/api-calls';
import Loading from '../components/Loading';
import { Avatar, Box, Card, CardContent, Grid, IconButton, Stack, Typography } from '@mui/material';
import { IMAGE_BASE_URL } from '../utils/constant';
import { useNavigate } from 'react-router-dom';
import { Edit, Group, Visibility } from '@material-ui/icons';
import { Diversity3, QueryStats } from '@mui/icons-material';

const GRID_ITEMS = [
  {
    label: 'Total Users',
    key: 'totalUsers',
    icon: <Group />,
  },
  {
    label: 'Total Employees',
    key: 'totalEmployees',
    icon: <Diversity3 />,
  },
  {
    label: 'Total Impressions',
    key: 'totalImpressions',
    icon: <QueryStats />,
  },
];

const Dashboard = () => {
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const [allStaff, setAllStaff] = useState([]);
  const [data, setData] = useState({
    piChatData: [],
    totalEmployees: null,
    totalUsers: null,
    totalImpressions: null,
  });
  const [totalEmployees, settotalEmployees] = useState();
  const [totalImpressions, settotalImpressions] = useState();
  const [totalUsers, settotalUsers] = useState();

  React.useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await dashboadData();
      console.log('res in screen=>', res);
      if (res?.data) {
        const { piChatData, totalEmployees, totalUsers, totalImpressions } = res.data || {};

        setData({ pichartData: piChatData, totalEmployees, totalUsers, totalImpressions });
      }
      setLoading(false);
    })();
  }, []);

  const getStaffList = async signal => {
    const resp = await GetAll();

    if (resp?.data) {
      setAllStaff(resp.data);
    }
  };

  useEffect(() => {
    getStaffList();
  }, []);

  if (loading) return <Loading />;

  return (
    <div className="container">
      <Grid container spacing={3}>
        {GRID_ITEMS.map(({ label, key, icon }) => (
          <Grid key={key} item xs={12} md={4}>
            <Card sx={{ bgcolor: ({ palette }) => palette.primary.main, color: 'white' }}>
              <CardContent>
                <Typography textAlign="center" mb={2} variant="h6">
                  {icon} {label}
                </Typography>
                <Typography textAlign="center">{data[key] ?? 'Na'}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={3} mt={3}>
        {allStaff?.map((item, index) => (
          <Grid key={item?.id} item xs={12} md={4}>
            <Card elevation={0} className="shadow">
              <CardContent>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Stack direction="row" alignItems="center">
                    <Box>
                      {`${item?.profile}` !== '' ? (
                        <Avatar round name={item?.name} src={`${IMAGE_BASE_URL}${item?.profile}`} />
                      ) : (
                        <Avatar round name={item?.name} />
                      )}
                    </Box>
                    <Box>
                      <Typography ml="5px">{item?.name}</Typography>
                      <Typography display="block" variant="caption" ml="5px">
                        {item?.email}
                      </Typography>
                    </Box>
                  </Stack>
                  <Stack alignItems="center" gap={0.5} flexDirection="row">
                    <IconButton onClick={() => navigate('/detail', { state: { item: item } })} size="small">
                      <Visibility />
                    </IconButton>
                    <IconButton
                      onClick={() => navigate('/editProfile', { state: { item: item } })}
                      size="small"
                    >
                      <Edit />
                    </IconButton>
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Dashboard;
