import {
  Avatar,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { Link } from 'react-router-dom';

function ChatListItem({ data }) {
  const { userName, userId, image, lastMessage } = data;

  const { createdAt, description } = lastMessage || {};

  return (
    <Link className="reset-link" to={`/chat/${userId}`} state={data}>
      <ListItem sx={{ px: 0 }}>
        <ListItemButton disableGutters>
          <ListItemIcon>
            <Avatar alt={userName} src={image === '' ? '/' : image} />
          </ListItemIcon>
          <ListItemText>
            <Stack direction="row" justifyContent="space-between" gap={1}>
              <Typography>{userName}</Typography>
              <Typography color="gray" variant="caption">
                {moment(createdAt).format('hh:mm A')}
              </Typography>
            </Stack>
            <Typography color="gray">{description}</Typography>
          </ListItemText>
        </ListItemButton>
      </ListItem>
    </Link>
  );
}

export default ChatListItem;
