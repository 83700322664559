import {
  Box,
  Container,
  Avatar,
  IconButton,
  Stack,
  Typography,
  TextField,
  InputAdornment,
  CircularProgress,
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import useAppbarHeight from '../../../hooks/useAppbarHeight';
import { ArrowBack } from '@material-ui/icons';
import { MoreVert, Send } from '@mui/icons-material';
import Message from './Message';
import { GetMessage, SendMessage } from '../../../utils/api-calls';
import { toast } from 'react-toastify';
import useUser from '../../../hooks/useUser';
import { flushSync } from 'react-dom';

function ChatDetail() {
  const { isAdmin, user } = useUser();

  const messageContainerRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [msg, setMessage] = useState('');
  const [loaders, setLoaders] = useState({
    messageList: true,
    sendingMessage: false,
  });
  const bottomEl = useRef(null);
  const navigate = useNavigate();
  const navHeight = useAppbarHeight();
  const { id } = useParams();

  const { state } = useLocation();

  const { userName, photo } = state || {};

  const scrollToBottom = useCallback(() => {
    if (messageContainerRef?.current) {
      messageContainerRef.current.lastElementChild.scrollIntoView({
        behavior: 'instant',
        block: 'end',
        inline: 'end',
      });
    }
  }, []);

  const handleSendMessage = useCallback(
    async (msg = '') => {
      if (!msg || !msg.trim()) {
        toast.error('Message cannot be empty!');
        return;
      }

      if (loaders.sendingMessage) return;

      setLoaders(prev => ({ ...prev, sendingMessage: true }));

      // console.log("🚀 ~ file: chat.js:39 ~ onMessageSend ~ formData:", formData)
      const res = await SendMessage({
        IsSeen: false,
        IsFromAdmin: isAdmin,
        IsToAdmin: false,
        Type: 'text',
        Description: msg,
        To: id,
        From: user?.id,
      });

      if (res?.Succeeded || res?.succeeded) {
        flushSync(() => {
          setMessages(prev => [...prev, res.data]);
        });

        scrollToBottom();
        setMessage('');
      }

      setLoaders(prev => ({ ...prev, sendingMessage: false }));
    },
    [loaders.sendingMessage, id, isAdmin, user?.id, scrollToBottom]
  );

  const MessageList = useMemo(() => {
    return (
      <Stack minHeight={0}>
        <Box ref={messageContainerRef} flex={1} sx={{ overflowY: 'auto' }}>
          {messages.map((m, i) => (
            <Message key={m.id} detail={m} scrollRef={bottomEl} isLast={i === messages.length - 1} />
          ))}

          <div style={{ visibility: 'hidden' }} ref={bottomEl} />
        </Box>
      </Stack>
    );
  }, [messages]);

  useEffect(() => {
    const ctrl = new AbortController();

    const get = async () => {
      const resp = await GetMessage(id, { signal: ctrl.signal });
      console.log(resp, 'messages');
      if (resp?.data) {
        setMessages(resp.data);
      }

      setLoaders(prev => ({ ...prev, messageList: false }));
    };

    get();

    return () => {
      ctrl.abort();
      setLoaders(prev => ({ ...prev, messageList: true }));
    };
  }, [id]);

  if (!state?.userName) return <Navigate replace to="/chat" />;

  return (
    <Container className="shadow rounded-3" sx={{ height: `calc(100dvh - ${navHeight}px)` }} maxWidth="sm">
      {loaders.messageList ? (
        <Box
          textAlign="center"
          position="relative"
          top="50%"
          left="50%"
          sx={{ transform: 'translate(-50%,-50%)' }}
        >
          <CircularProgress size="3rem" />
        </Box>
      ) : (
        <Stack direction="column" height={1} py={2}>
          {/* Header */}

          <Stack
            py={1}
            className="border-bottom"
            justifyContent="space-between"
            direction="row"
            alignItems="center"
          >
            <Stack direction="row" alignItems="center">
              <IconButton className="borer me-3" onClick={() => navigate(-1)}>
                <ArrowBack />
              </IconButton>
              <Avatar
                sx={{ height: '40px', width: '40px' }}
                alt={userName}
                src={photo === '' ? '/' : photo}
              />
              <Typography ml={1}>{userName}</Typography>
            </Stack>

            {/* <IconButton onClick={() => {}}>
              <MoreVert />
            </IconButton> */}
          </Stack>

          {MessageList}

          {/* Footer */}

          <Stack mt="auto" justifyContent="space-between" direction="row" alignItems="center">
            <TextField
              value={msg}
              onChange={({ target }) => setMessage(target.value)}
              fullWidth
              variant="outlined"
              type="text"
              autoComplete="new-password"
              placeholder="Type a message..."
              inputRef={input => input && input.focus()}
              onKeyUp={e => {
                if (e.code === 'Enter') {
                  handleSendMessage(e.target.value);
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {loaders.sendingMessage ? (
                      <CircularProgress size="20px" />
                    ) : (
                      <IconButton disabled={!msg.trim()} onClick={() => handleSendMessage(msg)}>
                        <Send />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        </Stack>
      )}
    </Container>
  );
}

export default ChatDetail;
