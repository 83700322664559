import { useEffect, useState } from 'react';
import PageHeader from '../../components/PageHeader';
import UsersList from './components/UsersList';

function SendNotifications() {
  return (
    <div>
      <PageHeader backBtn={false} title="Notify Users" />
      <UsersList />
    </div>
  );
}

export default SendNotifications;
