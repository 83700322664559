import React, { useEffect, useMemo, useState } from 'react';
import { Box, CircularProgress, InputAdornment, List, TextField, Typography } from '@mui/material';
import { Markunread, Search } from '@material-ui/icons';
import ChatListItem from './ChatListItem';
import { GetChatList } from '../../utils/api-calls';

function ChatList() {
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);

  const filteredList = useMemo(() => {
    return list.filter(
      item => item?.userName?.toLocaleLowerCase().lastIndexOf(search.toLocaleLowerCase()) !== -1
    );
  }, [search, list]);

  useEffect(() => {
    const ctrl = new AbortController();

    const get = async () => {
      setLoading(true);
      const list = await GetChatList({ signal: ctrl.signal });

      if (list?.data) {
        setList(list.data);
      }
      setLoading(false);
    };

    get();

    return () => {
      ctrl.abort();
      setLoading(true);
    };
  }, []);

  if (loading) {
    return (
      <Box
        textAlign="center"
        position="relative"
        top="50%"
        left="50%"
        sx={{ transform: 'translate(-50%,-50%)' }}
      >
        <CircularProgress size="3rem" />
      </Box>
    );
  }

  return (
    <Box py={3}>
      <TextField
        value={search}
        onChange={({ target }) => setSearch(target.value)}
        variant="outlined"
        placeholder="search..."
        size="small"
        fullWidth
        autoComplete="new-password"
        type="search"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Search />
            </InputAdornment>
          ),
        }}
      />

      {filteredList.length > 0 ? (
        <List sx={{ mt: 3 }} dense disablePadding>
          {filteredList.map(item => (
            <ChatListItem key={item?.userId} data={item} />
          ))}
        </List>
      ) : (
        <Box mt={16} textAlign="center">
          <Markunread fontSize="large" />
          <Typography>Could not find any chats!</Typography>
        </Box>
      )}
    </Box>
  );
}

export default ChatList;
