import React, { useState } from 'react';
import {
  Button,
  TextField,
  Select,
  MenuItem,
  Stack,
  Container,
  FormControl,
  InputLabel,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { VerifyUser } from '../utils/api-calls';
import PageHeader from '../components/PageHeader';
import { toast } from 'react-toastify';

const actionMapping = {
  verify: true,
  remove_verify: false,
};

const VerifyCreator = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [action, setAction] = useState('');
  const [submitting, setSubmitting] = useState(false);

  // Handle email input change
  const handleEmailChange = event => {
    setEmail(event.target.value);
  };

  // Handle dropdown selection change
  const handleActionChange = (event, child) => {
    setAction(event.target.value);
  };

  // Handle form submission
  const handleSubmit = async e => {
    e.preventDefault();
    setSubmitting(true);
    const isApprove = actionMapping[action];

    if (email && action !== undefined) {
      const payload = {
        email: email,
        isApprove,
      };

      try {
        const resp = await VerifyUser(payload);
        if (!resp.succeeded) {
          toast.error(resp.response.data.Message);
        } else {
          toast.success('User Verification Status Updated Successfully');
        }
        console.log(resp);
      } catch (error) {
        toast.error(`${error.response.data.Message}`);
      }
      setSubmitting(false);
    }
  };

  return (
    <div>
      <PageHeader title="Verify Creator" />
      <Container maxWidth="sm" className="mt-5">
        <form onSubmit={handleSubmit}>
          <Stack gap={3}>
            <TextField
              required
              type="email"
              label="Email"
              variant="outlined"
              value={email}
              onChange={handleEmailChange}
            />
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-helper-label">Select Action</InputLabel>

              <Select
                labelId="demo-simple-select-helper-label"
                required
                label="Select Action"
                value={action}
                onChange={handleActionChange}
              >
                {!action && (
                  <MenuItem value="" disabled>
                    Select Action
                  </MenuItem>
                )}
                <MenuItem data-payload={true} value="verify">
                  Verify
                </MenuItem>
                <MenuItem data-payload={false} value="remove_verify">
                  Remove Verify
                </MenuItem>
              </Select>
            </FormControl>
            <Button disabled={submitting} variant="contained" type="submit">
              Submit
            </Button>
          </Stack>
        </form>
      </Container>
    </div>
  );
};

export default VerifyCreator;
