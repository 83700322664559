import axios from 'axios';
import client from '../services/api/axios-interceptor';

//const BASE_URL = "http://kankiraapp-001-site1.etempurl.com";
const BASE_URL = 'https://dashboardapi.odysseydesignco.com/api';
export const IMAGE_BASE_URL = 'http://kankiraapp-001-site1.etempurl.com/UploadedFiles/';
const options = {
  headers: {
    Authorization: 'bearer ' + localStorage.getItem('@token'),
    'Content-Type': 'Application/json',
  },
};
const formDataOptions = {
  // const token = localStorage.getItem('@token');
  headers: {
    Authorization: 'bearer ' + localStorage.getItem('@token'),
    'Content-Type': 'multipart/form-data',
  },
};
export const GetData = async (url, config = {}) => {
  const { data } = await client.get(url, { ...options, ...config });
  return data;
};
export const GetFormData = async url => {
  const { data } = await client.get(url, formDataOptions);
  return data;
};
export const PostData = async (url, payload, config = {}) => {
  const { data } = await client.post(url, payload, { ...options, ...config });
  return data;
};
export const PostFormData = async (url, payload) => {
  const { data } = await client.post(url, payload, formDataOptions);
  return data;
};
export const PutData = async (url, payload) => {
  const { data } = await client.put(url, payload, options);
  return data;
};
export const PutFormData = async (url, payload) => {
  const { data } = await client.put(url, payload, formDataOptions);
  return data;
};
export const DeleteData = async url => {
  const { data } = await client.delete(url, options);
  return data;
};
