//export const IP = "http://kankiraapp-001-site1.etempurl.com";
//export const IP = "http://webapi.kankira.com";
export const IP = 'https://kankiraapp-001-site4.etempurl.com/';
export const URLS = {
  //  base_url: `https://localhost:7238/api/`,
  base_url: `https://kankiraapp-001-site4.etempurl.com/`,
  //base_url: `http://webapi.kankira.com/api/`,
  image_url: `${IP}/`,
  staff: {
    signup: '/api/staff/register',
    login: '/api/staff/login',
    get_all: '/api/staff/get-all',
    update_access: '/api/staff/update_access',
  },
};
