import { useLayoutEffect, useState } from 'react';

function useAppbarHeight() {
  const [navHeight, setNavHeight] = useState(60);

  useLayoutEffect(() => {
    const top = document.getElementById('top-app-bar');

    if (top?.clientHeight) {
      setNavHeight(top.clientHeight + 48);
    }
  }, []);

  return navHeight;
}

export default useAppbarHeight;
