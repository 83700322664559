import { toast } from 'react-toastify';
import { URLS } from '../services/api/api-urls';
import { DeleteData, GetData, GetFormData, PostData, PutData } from './constant';
import client from '../services/api/axios-interceptor';

export const login = async paylaod => {
  const res = await PostData('/api/staff/login', paylaod);
  if (res?.data?.succeeded) {
    localStorage.setItem('token', res?.data?.data?.token);
    localStorage.setItem('user', JSON.stringify(res?.data?.data?.user));
  }
  return res;
};
export const GetAll = async () => {
  const res = await GetFormData('api/staff/get-all');
  return res;
};

export const GetAllUsers = async (payload, config = {}) => {
  const res = await PostData('api/admin/get-all-users', payload, config);
  return res;
};

export const Register = async paylaod => {
  const res = await fetch(`${URLS.base_url}api/staff/register`, {
    body: paylaod,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('@token')}`,
    },
    method: 'POST',
  }).then(res => res?.json());

  return res;
};

export const SendMessage = async paylaod => {
  const body = new FormData();
  Object.entries(paylaod).forEach(([key, val]) => {
    body.append(key, val);
  });

  const res = await fetch(`${URLS.base_url}api/message/send`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('@token')}`,
    },
    body,
  });

  const json = await res?.json();

  if (res.status >= 400) {
    toast.error(json?.message || json?.Message || 'Something went wrong!');
  }

  return json;
};

export const UpdateWithdrawRequestStatus = async paylaod => {
  const res = await PostData('api/withdraw/update-status', paylaod);
  console.log('Response of api is == > ', res);
  return res;
};

export const AddCountryPrice = async paylaod => {
  const res = await PostData('api/country-price/add-country-price', paylaod);
  if (res?.data?.succeeded) {
    console.log('successfully Message Send!');
  }
  return res;
};

export const UpdateStaff = async paylaod => {
  const res = await PutData('api/staff/update-access', paylaod);
  if (res?.data?.succeeded) {
    console.log('successfully Added!');
  }

  return res;
};

export const BlockUser = async id => {
  const res = await PutData(`api/user/block-user/${id}`, {});
  if (res?.data?.succeeded) {
    console.log('successfully Added!');
  }

  return res;
};

export const UnBlockUser = async id => {
  const res = await PutData(`api/user/unblock-user/${id}`, {});
  if (res?.data?.succeeded) {
    console.log('successfully Added!');
  }

  return res;
};

export const GetCustomeServices = async (config = {}) => {
  const res = await GetData('api/admin/get-customer-service-counts', config);
  return res;
};

export const GetReportedContent = async () => {
  const res = await GetData('api/admin/get-reported-content');
  return res;
};

export const GetReportedUser = async () => {
  const res = await GetData('api/admin/get-reported-users');
  return res;
};

export const GetChatList = async (config = {}) => {
  const res = await GetData('api/admin/get-chat-list', config);
  return res;
};

export const GetMessage = async (id, config = {}) => {
  try {
    const res = await GetData(`api/admin/get-messages/${id}`, config);
    return res;
  } catch (error) {
    return { error };
  }
};

export const GetRequestType = async type => {
  const res = await GetData(`api/creator-request/get-requests/${type}`);
  return res;
};

export const getCreatorEarnings = async id => {
  const res = await GetData(`api/withdraw/get-earnings/${id}`);
  return res;
};

export const getWithdrawRequests = async (config = {}) => {
  const res = await GetData(`api/withdraw/get-all`, config);
  return res;
};

export const getBankAccount = async userId => {
  const res = await GetData(`api/bank-account/get/${userId}`);
  return res;
};
export const GetRequestsCount = async () => {
  const res = await GetData('api/creator-request/get-requests-count');
  return res;
};
export const GetCountries = async () => {
  const res = await GetData('api/country-price/get-countries');
  return res;
};

export const ApproveRequest = async id => {
  const res = await PutData(`api/withdraw/approve/${id}`, {});
  if (res?.data?.succeeded) {
    console.log('successfully Update!');
  }
  return res;
};

export const RejectRequest = async id => {
  const res = await PutData(`api/withdraw/reject/${id}`, {});
  if (res?.data?.succeeded) {
    console.log('successfully Update!');
  }
  return res;
};

export const DeleteContent = async id => {
  const res = await DeleteData(`api/content/delete-content/${id}`);
  if (res?.data?.succeeded) {
    console.log('successfully Delete!');
  }
  return res;
};

export const VerifyUser = async payload => {
  try {
    const res = await PostData(`api/admin/update-approve-user`, payload);
    return res;
  } catch (error) {
    // Handle the error here
    return error;
  }
};

export const UpdateCreatorRole = async paylaod => {
  const res = await PostData('api/admin/update-creator-role', paylaod);
  if (res?.data?.succeeded) {
    console.log('successfully Added!');
  }

  return res;
};

export const UpdateUserCall = async paylaod => {
  const res = await PostData('api/admin/update-user', paylaod);
  if (res?.data?.succeeded) {
    console.log('successfully Added!');
  }

  return res;
};

export const DeleteUser = async userId => {
  const res = await GetData(`api/admin/delete-user/${userId}`);
  return res;
};

export const dashboadData = async () => {
  const res = await GetData(`api/admin/dashboadData`);
  return res;
};

export const blockUser = async id => {
  const res = await client.put(`api/user/block-user/${id}`);
  return res;
};

export const unblockUser = async id => {
  const res = await client.put(`api/user/unblock-user/${id}`);
  return res;
};
