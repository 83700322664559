import { Box, Container, IconButton, Stack, Tab, Tabs } from '@mui/material';
import useAppbarHeight from '../../hooks/useAppbarHeight';
import ChatList from './ChatList';
import { useState } from 'react';
import UsersList from './user-list/UsersList';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const tabs = {
  0: <ChatList />,
  1: <UsersList />,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function ChatPage() {
  const [tab, setTab] = useState(0);
  const navigate = useNavigate();
  const navHeight = useAppbarHeight();
  return (
    <Container className="shadow rounded-3" sx={{ height: `calc(100dvh - ${navHeight}px)` }} maxWidth="sm">
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{ borderBottom: 1, borderColor: 'divider' }}
      >
        <Tabs value={tab} onChange={(e, v) => setTab(v)} aria-label="basic tabs example">
          <Tab label="Chats" {...a11yProps(0)} />
          <Tab label="New Message" {...a11yProps(1)} />
        </Tabs>

        <IconButton onClick={() => navigate(-1)}>
          <ArrowBack />
        </IconButton>
      </Stack>

      {tabs[tab]}
    </Container>
  );
}

export default ChatPage;
