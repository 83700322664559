import { ArrowBack } from '@mui/icons-material';
import { IconButton, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

/**
 *  @param buttons:JSX | boolean
 *  @param title:string
 */

function PageHeader({ title = '', customBtn = null, backBtn = true }) {
  const navigate = useNavigate();

  return (
    <Stack direction="row" mb={3} justifyContent="space-between">
      <Typography variant="h6">{title}</Typography>

      {customBtn && customBtn}

      {!customBtn && backBtn && (
        <IconButton className="border" onClick={() => navigate(-1)}>
          <ArrowBack />
        </IconButton>
      )}
    </Stack>
  );
}

export default PageHeader;
