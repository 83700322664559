import React, { useMemo, useState } from 'react';
import { Box, CircularProgress, InputAdornment, List, TextField, Typography } from '@mui/material';
import { Markunread, Search } from '@material-ui/icons';
import RequestListItem from './RequestListItem';

function RequestList({ data = [], loading }) {
  const [search, setSearch] = useState('');

  const filteredList = useMemo(() => {
    return data.filter(
      item => item?.user?.userName?.toLocaleLowerCase().lastIndexOf(search.toLocaleLowerCase()) !== -1
    );
  }, [search, data]);

  return (
    <Box py={3}>
      <TextField
        value={search}
        onChange={({ target }) => setSearch(target.value)}
        variant="outlined"
        placeholder="search..."
        size="small"
        fullWidth
        autoComplete="new-password"
        type="search"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Search />
            </InputAdornment>
          ),
        }}
      />

      {loading && (
        <Box mt={10} textAlign="center" position="relative" left="50%" sx={{ transform: 'translateX(-50%)' }}>
          <CircularProgress size="3rem" />
        </Box>
      )}

      {filteredList.length > 0 ? (
        <List sx={{ mt: 3 }} dense disablePadding>
          {filteredList.map(item => (
            <RequestListItem key={item?.user?.id} data={item} />
          ))}
        </List>
      ) : (
        <>
          {!loading && (
            <Box mt={16} textAlign="center">
              <Markunread fontSize="large" />
              <Typography>Could not find any requests!</Typography>
            </Box>
          )}
        </>
      )}
    </Box>
  );
}

export default RequestList;
