import React from 'react';
import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ClearIcon from '@mui/icons-material/Clear';
import VerifiedIcon from '@mui/icons-material/Verified';
import TablePagination from '@mui/material/TablePagination';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  DialogContentText,
  DialogActions,
  Button,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
  Chip,
} from '@mui/material';
import { GetAllUsers, blockUser, unblockUser } from '../utils/api-calls';
import Loading from '../components/Loading';
import { UpdateCreatorRole } from '../utils/api-calls';
import { UpdateUserCall } from '../utils/api-calls';
import { DeleteUser } from '../utils/api-calls';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import styled from 'styled-components';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import { toast } from 'react-toastify';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
`;

const RadioContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  .clear-icon {
    margin-top: 15px;
  }
`;

const PageSize = 10;

const UserData = () => {
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [isConfirmEmail, setIsConfirmEmail] = useState(false);
  const [isCreator, setIsCreator] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [openDelete, setOpenDelete] = useState(false);
  const [totalUser, settotalUser] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchString, setSearchString] = useState('');

  React.useEffect(() => {
    const ctrl = new AbortController();
    (async () => {
      const obj = {
        PageNo: 0,
        PageSize,
        SearchString: searchString === '' ? null : searchString,
      };
      await GetAllUsersCall(obj, { signal: ctrl.signal });
    })();

    return () => {
      ctrl.abort();
    };
  }, []);

  const GetAllUsersCall = async (obj, config = {}) => {
    const res = await GetAllUsers(obj, config);
    setUserData(res?.data.items || []);
    setPage(res?.data.page);
    setRowsPerPage(res?.data.pageSize);
    settotalUser(res?.data.totalItems);
  };

  const handleClickOpen = () => {
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleEditClick = () => {
    setUserName(selectedRow.userName);
    setEmail(selectedRow.email);
    setIsConfirmEmail(selectedRow.isConfirmEmail);
    setIsCreator(selectedRow.isCreator);
    handleClose();
    setOpen(true);
  };

  const handleDeleteClick = async () => {
    try {
      const res = await DeleteUser(selectedRow.id);
      setLoading(true);
      const obj = {
        PageNo: 0,
        PageSize,
        SearchString: searchString == '' ? null : searchString,
      };
      await GetAllUsersCall(obj);
      setLoading(false);
      handleClose();
      setOpenDelete(false);
    } catch (error) {}
  };

  const handleSave = async () => {
    try {
      var obj = {
        id: selectedRow.id,
        userName: userName,
        email: email,
        isConfirmEmail: isConfirmEmail,
        isCreator: isCreator,
      };
      const res = await UpdateUserCall(obj);
      setLoading(true);
      const obj2 = {
        PageNo: 0,
        PageSize,
        SearchString: searchString == '' ? null : searchString,
      };
      await GetAllUsersCall(obj2);
      setLoading(false);
      handleClose();
      setOpen(false);
    } catch (error) {}
  };

  const handleMenuClick = (event, obj) => {
    setSelectedRow(obj);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSubmit = async payload => {
    try {
      var obj = {
        id: payload.id,
        isCreator: !payload.isCreator,
      };
      const res = await UpdateCreatorRole(obj);
      setLoading(true);
      const obj2 = {
        PageNo: 0,
        PageSize,
        SearchString: searchString == '' ? null : searchString,
      };
      await GetAllUsersCall(obj2);
      handleClose();
      setLoading(false);
    } catch (error) {}
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    setLoading(true);
    var obj = {
      PageNo: newPage,
      PageSize,
      SearchString: searchString == '' ? null : searchString,
    };
    const res = await GetAllUsers(obj);
    setUserData(res?.data.items || []);
    setPage(res?.data.page);
    setRowsPerPage(res?.data.pageSize);
    settotalUser(res?.data.totalItems);
    setLoading(false);
  };

  const handleChangeRowsPerPage = async event => {
    setRowsPerPage(+event.target.value);
    setPage(0);

    setLoading(true);
    var obj = {
      PageNo: page,
      PageSize: +event.target.value,
      SearchString: searchString == '' ? null : searchString,
    };
    const res = await GetAllUsers(obj);
    console.log('res in screen=>', res);
    setUserData(res?.data.items || []);
    setPage(res?.data.page);
    setRowsPerPage(res?.data.pageSize);
    settotalUser(res?.data.totalItems);
    setLoading(false);
  };
  const handleSearch = async event => {
    setSearchString(event.target.value);
    var obj = {
      PageNo: 0,
      PageSize: rowsPerPage,
      SearchString: event.target.value == '' ? null : event.target.value,
    };
    const res = await GetAllUsers(obj);
    console.log('res in screen=>', res);
    setUserData(res?.data.items || []);
    setPage(res?.data.page);
    setRowsPerPage(res?.data.pageSize);
    settotalUser(res?.data.totalItems);
  };

  const handleBlockUser = async () => {
    setAnchorEl(null);
    const resp = await blockUser(selectedRow.id);
    if (resp?.data?.succeeded || resp?.data?.Succeeded) {
      toast.success(`${selectedRow?.userName} blocked successfully`);
    }
    const payload = {
      PageNo: page,
      PageSize,
      SearchString: searchString === '' ? null : searchString,
    };
    const res = await GetAllUsers(payload);
    setUserData(res?.data.items || []);
    setPage(res?.data.page);
    setRowsPerPage(res?.data.pageSize);
    settotalUser(res?.data.totalItems);
  };

  const handleUnblockUser = async () => {
    setAnchorEl(null);
    const resp = await unblockUser(selectedRow.id);
    if (resp?.data?.succeeded || resp?.data?.Succeeded) {
      toast.success(`${selectedRow?.userName} unblocked successfully`);
    }
    const payload = {
      PageNo: page,
      PageSize,
      SearchString: searchString === '' ? null : searchString,
    };
    const res = await GetAllUsers(payload);
    setUserData(res?.data.items || []);
    setPage(res?.data.page);
    setRowsPerPage(res?.data.pageSize);
    settotalUser(res?.data.totalItems);
  };

  if (loading) return <Loading />;

  return (
    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection="column">
      <Paper sx={{ width: '100%' }}>
        <Box mb={4}>
          <TextField
            label="Search Users"
            variant="outlined"
            size="small"
            value={searchString}
            onChange={handleSearch}
          />
        </Box>
        <TableContainer sx={{ maxHeight: 700 }}>
          <Table size="small" stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell align="left">User Info</TableCell>
                {/* <TableCell align="center">Email</TableCell> */}
                {/* <TableCell align="center">Is Confirm Email</TableCell> */}
                {/* <TableCell align="center">Is Creator</TableCell> */}
                <TableCell align="left">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ height: 20 }}>
              {userData?.map((e, i) => (
                <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell align="left">
                    <Stack gap={0.3}>
                      <Stack direction="row" gap={1}>
                        <Typography>{e.userName}</Typography>
                        {/* {e.isCreator && <Chip variant="outlined" color="info" label="Creator" size="small" />} */}
                      </Stack>
                      <Stack direction="row" gap={1}>
                        <Typography variant="caption" display="block">
                          {e.email}
                        </Typography>
                        {/* {e.isConfirmEmail ? (
                          <VerifiedIcon color="info" sx={{ height: 16, width: 16 }} />
                        ) : (
                          <NewReleasesIcon color="warning" sx={{ height: 16, width: 16 }} />
                        )} */}
                      </Stack>
                    </Stack>
                  </TableCell>
                  {/* <TableCell align="center">{e.email}</TableCell> */}
                  {/* <TableCell align="center">{e.isConfirmEmail === true ? 'Yes' : 'No'}</TableCell> */}
                  {/* <TableCell align="center">{e.isCreator === true ? 'Yes' : 'No'}</TableCell> */}
                  <TableCell align="left">
                    <IconButton
                      size="small"
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={event => handleMenuClick(event, e)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={totalUser || 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            padding: theme => theme.spacing(2),
            '& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows': {
              marginBottom: 0,
            },
          }}
        />
      </Paper>
      <Dialog onClose={handleClose} open={open}>
        <TitleContainer>
          <DialogTitle>Edit User</DialogTitle>
          <p className="clear-icon" onClick={() => setOpen(false)}>
            <ClearIcon />
          </p>
        </TitleContainer>
        <DialogContent>
          <TextField
            label="User Name"
            value={userName}
            onChange={e => setUserName(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            fullWidth
            margin="normal"
          />
          {isConfirmEmail}
          <RadioContainer>
            <label>Is Email Confirmed</label>

            <RadioGroup
              aria-label="Is Confirm Email ?"
              name="isConfirmEmail"
              value={isConfirmEmail ? 'yes' : 'no'}
              onChange={e => setIsConfirmEmail(e.target.value === 'yes')}
            >
              <RadioContainer>
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioContainer>
            </RadioGroup>
          </RadioContainer>
          <RadioContainer>
            <label>Is Creator Confirmed</label>
            <RadioGroup
              aria-label="is Creator?"
              name="isCreator"
              value={isCreator ? 'yes' : 'no'}
              onChange={e => setIsCreator(e.target.value === 'yes')}
            >
              <RadioContainer>
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioContainer>
            </RadioGroup>
          </RadioContainer>
          <ButtonContainer>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setOpen(false);
                handleClose();
              }}
            >
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={handleSave}>
              Edit
            </Button>
          </ButtonContainer>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Delete'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to Delete?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleCloseDelete();
              handleClose();
            }}
            className="primary"
          >
            No
          </Button>
          <Button
            onClick={() => {
              handleCloseDelete();
              handleDeleteClick();
            }}
            className="secondary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      {/* menu */}
      <Menu id="menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {/* <MenuItem onClick={event => handleEditClick()}>Edit</MenuItem>
                      <MenuItem onClick={() => onSubmit(updateUser)}>
                      {selectedRow.isCreator === true ? 'Remove Creator Role' : 'Assign Creator Role'}
                    </MenuItem>` */}
        {selectedRow?.isBlock ? (
          <MenuItem dense onClick={handleUnblockUser}>
            Unblock
          </MenuItem>
        ) : (
          <MenuItem dense onClick={handleBlockUser}>
            Block
          </MenuItem>
        )}
        <MenuItem
          dense
          onClick={() => {
            handleClickOpen();
            handleClose();
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default UserData;
