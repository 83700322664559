import { Checkbox, Stack, Typography } from '@mui/material';
import React, { memo } from 'react';

function UserListItem({ user = {}, isSelected, onChange = () => {} }) {
  const { id, fullName } = user;

  return (
    <Stack bgcolor="rgb(233, 233, 233)" borderRadius="4px" direction="row" alignItems="center" gap={1}>
      <Checkbox checked={!!isSelected} onChange={e => onChange(e, user)} />
      <Typography>{fullName}</Typography>
    </Stack>
  );
}

export default memo(UserListItem);
